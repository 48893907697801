import React from 'react';
import withInjectedProps from '@atlassian/jira-common-util-with-injected-props';
import { getLastVisitedQueueIdAndCategory } from '@atlassian/jira-servicedesk-queues-available-queues/src/services/last-visited-queue-helper';
import { AvailableQueuesConsumer as AvailableQueuesConsumerDI } from '@atlassian/jira-servicedesk-queues-available-queues/src/services/queues-api-data-provider/main.tsx';
import { useCategorizedQueues } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import QueuesAppBaseDI from '@atlassian/jira-spa-apps-queues-app-base/src/index.tsx';
import { pageToQueues } from '@atlassian/jira-spa-apps-queues-app-base/src/utils/data-transformer';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { useRouter } from '@atlassian/react-resource-router';
import type { Props } from './types';
import QueuesRedirectDI from './ui/queues-redirect/index.tsx';

const QueuesSpa = (props: Props) => {
	const { QueuesAppBase, QueuesRedirect } = props;

	const [{ match, query }] = useRouter();
	const projectKey = toProjectKey(match.params.projectKey || '');
	const itsmPractice = toItsmPractice(match.params.practiceType);
	const baseUrl = '';

	const [lastVisitedQueueId, lastVisitedCategory] = getLastVisitedQueueIdAndCategory(
		projectKey,
		itsmPractice,
	);

	const [categorizedQueues, categorizedQueuesLoading, categorizedQueuesError] =
		useCategorizedQueues(baseUrl, projectKey, lastVisitedCategory || undefined);

	return (
		<UFOSegment name="queues-landing-spa">
			<QueuesAppBase pageId="queues-landing">
				{({ pageContext, replace }) => (
					<QueuesRedirect
						queuesData={{
							data: pageToQueues(categorizedQueues || []),
							error: categorizedQueuesError ? categorizedQueuesError.message : undefined,
							loading: categorizedQueuesLoading,
						}}
						pageContext={pageContext}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						replace={replace!}
						lastVisitedQueueId={lastVisitedQueueId}
						queryParams={query}
					/>
				)}
			</QueuesAppBase>
		</UFOSegment>
	);
};

export default withInjectedProps(QueuesSpa, {
	QueuesAppBase: QueuesAppBaseDI,
	QueuesRedirect: QueuesRedirectDI,
	AvailableQueuesConsumer: AvailableQueuesConsumerDI,
});
