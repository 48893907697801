import React from 'react';
import groupBy from 'lodash/groupBy';
import minBy from 'lodash/minBy';
import withInjectedProps from '@atlassian/jira-common-util-with-injected-props';
import { getQueueUrl } from '@atlassian/jira-servicedesk-common/src/navigation/queues';
import type { QueuesDataSource } from '@atlassian/jira-servicedesk-queues-available-queues/src/services/queues-api-data-provider/types.tsx';
import {
	fromQueueIdString,
	type Queues,
} from '@atlassian/jira-servicedesk-queues-common/src/model';
import type { PageProps } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-context/types.tsx';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	Redirect as RedirectDI,
	type Query,
	type RouterActionReplace as Replace,
} from '@atlassian/react-resource-router';
import DefaultQueue from './default-queue';

type Props = {
	Redirect: typeof RedirectDI;
	queuesData: QueuesDataSource;
	pageContext: PageProps;
	replace: Replace;
	lastVisitedQueueId: string | null;
	queryParams: Query | undefined;
};

const getHighestRankingQueue = (currentQueuesMap?: Queues): string | undefined => {
	const queueMaps = currentQueuesMap || {};
	const queueGroups = groupBy(queueMaps, (queue) => {
		if (queue.isFavorite) {
			return 'starred';
		}
		if (queue.canBeHidden) {
			return 'other';
		}
		return 'priority';
	});
	let queueGroup;
	if (queueGroups.starred && queueGroups.starred.length > 0) {
		queueGroup = queueGroups.starred;
	} else if (queueGroups.priority && queueGroups.priority.length > 0) {
		queueGroup = queueGroups.priority;
	} else {
		queueGroup = queueGroups.other;
	}
	// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
	return queueGroup && fromQueueIdString(minBy(queueGroup, (queue) => queue.order).id);
};

const getLastVisitedQueueId = (
	currentQueuesMap?: Queues,
	lastVisitedQueueId?: string | null,
): string | undefined | null =>
	lastVisitedQueueId &&
	currentQueuesMap &&
	Object.keys(currentQueuesMap).find((queue) => queue === lastVisitedQueueId);

export const QueuesRedirect = (props: Props) => {
	const { queuesData, Redirect, pageContext, replace, lastVisitedQueueId, queryParams } = props;
	const { userRole, projectKey } = pageContext;
	const hasNoAgentAccess = userRole !== 'agent-project-admin' && userRole !== 'agent';

	if (hasNoAgentAccess) {
		return <Redirect to={`/jira/servicedesk/projects/${projectKey}/issues`} />;
	}

	const currentQueuesMap = queuesData && queuesData.data;
	const queueToRedirectTo =
		getLastVisitedQueueId(currentQueuesMap, lastVisitedQueueId) ||
		getHighestRankingQueue(currentQueuesMap);

	const category =
		queueToRedirectTo && currentQueuesMap
			? currentQueuesMap[queueToRedirectTo].category
			: undefined;

	return queueToRedirectTo ? (
		<Redirect
			to={getQueueUrl({
				projectKey: toProjectKey(projectKey),
				category,
				queueId: Number(queueToRedirectTo),
				queryParams,
			})}
		/>
	) : (
		<>
			<DefaultQueue pageContext={pageContext} replace={replace} />
		</>
	);
};

export default withInjectedProps(QueuesRedirect, {
	Redirect: RedirectDI,
});
